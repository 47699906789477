<template>
  <div v-if="isModalOpen" id="modal-container" :class="{ active: isModalOpen }">
    <div class="modal-background modal-background--black">
      <div class="modal modal--full">
        <slot />

        <div class="close close--on-full-modal" @click="closeModal" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  clearAllBodyScrollLocks,
  disableBodyScroll
} from 'body-scroll-lock-upgrade'

export default {
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { isModalOpen: this.open }
  },
  mounted() {
    disableBodyScroll(document.body, { reserveScrollBarGap: true })
  },
  unmounted() {
    clearAllBodyScrollLocks()
  },
  methods: {
    closeModal() {
      this.isModalOpen = false
      this.$emit('on-close-modal')
    }
  }
}
</script>

<style lang="scss">
#modal-container {
  position: fixed;
  inset: 0;
  scale: 0;
  z-index: 999;

  &.active {
    z-index: 1000;
    scale: 1;

    .modal-background {
      background-color: hsl(0 0% 0% / 70%);
      height: 100%;

      .modal--full {
        width: 100%;
      }
    }
  }

  & .modal-background {
    background-color: hsl(0 0% 0% / 80%);
    text-align: center;
    vertical-align: middle;

    & .modal {
      display: inline-block;
      border-radius: em(3px);
      font-weight: 300;
      position: relative;
      padding: 0;
    }
  }
}

.close {
  position: absolute;
  cursor: pointer;
  top: -40px;
  right: 10px;
  width: 35px;
  height: 35px;
  z-index: z-index(base);

  @include media-query(sm) {
    top: -40px;
    right: -20px;
  }

  &.close--on-full-modal {
    background-color: color(flamingo);
    border-radius: em(2px);
    top: 8px;
    right: 16px;
    translate: -50%;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 2px;
    border-radius: em(4px);
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background-color: color(white);
    transition: background-color 0.2s ease;
  }

  &::before {
    transform: rotate(-45deg);
  }

  &::after {
    transform: rotate(45deg);
  }

  &:hover::before,
  &:hover::after {
    background-color: color(white);
  }
}
</style>
